import { useCallback, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';

import { useAddTeam } from "./hooks";

const AddTeam = () => {
  const navigate = useNavigate();

  const [teamName, setTeamName] = useState("");
  const [hasSubmittedTeam, setHasSubmittedTeam] = useState(false);
  
  const { user, setCurrentTeam } = useContext(AppContext);

  const userId = user.uid;
  const { isLoading, data } = useAddTeam({ teamName, userId, hasSubmittedTeam });

  useEffect(() => {
    if (data) {
      setCurrentTeam(data);
      setHasSubmittedTeam(false);
      navigate('/');
    }
  }, [data, setCurrentTeam, navigate]);

  const handleTeamNameChange = useCallback((e: any) => {
    setTeamName(e.target.value);
  }, []);

  const handleFormSubmit = useCallback((e: any) => {
    e.preventDefault();
    setHasSubmittedTeam(true);
  }, []);

  return (
    <motion.div
      initial={{
        x: "70%",
        opacity: 0,
      }}
      className="auth-wrapper"
      animate={{
        x: "0",
        opacity: 1,
        height: "100%",
      }}
      transition={{ duration: 1, delay: 0.1 }}
    >
      <h4>Tell us about your Guild</h4>
      <form onSubmit={handleFormSubmit}>
        <input type="text" id="teamName" placeholder='Team Name' value={teamName} onChange={handleTeamNameChange} />
        <button type="submit" className={teamName === '' || isLoading ? 'disabled' : ''}>Continue</button>
      </form>
    </motion.div>
  )
};

export default AddTeam;
