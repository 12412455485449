import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";

import { auth, db } from "../../services/firebase";
import Logger from "../../services/logger";

export const authStateChange = async () => {
  Logger.info("logging in user ", {});

  onAuthStateChanged(auth, (user) => {
    if (user) {
      sessionStorage.setItem('userId', user.uid);
    } else {
      sessionStorage.clear();
    }
  })
};

export const getUserName = async ({
  userId
}: {
  userId: string;
}) => {
  try {
    Logger.info("getUserName - fetch user details ", {
      userId
    });

    const userDocument = await getDoc(doc(db, 'users', userId));

    Logger.info("getUserName - userDocument ", { userDocument });

    if (userDocument.exists()) {
      Logger.info("getUserName - user found ", { user: userDocument.data() });

      return ({
        userId: userId,
        ...userDocument.data(),
      });
    }
    else {
      Logger.info("getUserName - no user found ", {});
      return ({
        status: 404,
        error: "User not found",
        name: '',
        email: '',
      });
    }
  } catch (e) {
    Logger.error("Error fetching document: ", {
      error: e
    });
  }
};
