import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../../services/firebase";
import Logger from "../../services/logger";

export const useAuthUser = () => {
  const [hasLoadedUser, setHasLoadedUser] = useState(false);
  const [user, setUser] = useState<any>(null);

  const subscribe = onAuthStateChanged(auth, (firebaseUser) => {
    if (!hasLoadedUser) {
      if (firebaseUser) {
        Logger.info("auth state changed to authenticated");
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          emailVerified: firebaseUser.emailVerified,
          phoneNumber: firebaseUser.phoneNumber,
          photoURL: firebaseUser.photoURL,
        });
        setHasLoadedUser(true);
      } else {
        Logger.info("auth state changed to not authenticated");
        setUser(null)
        setHasLoadedUser(true);
      }
    }
  });

  useEffect(() => {
    subscribe();
  }, [subscribe]);
  return { user, hasLoadedUser };
};