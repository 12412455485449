import { signOut as firebaseSignOut } from "firebase/auth";

import { auth } from "../../services/firebase";
import Logger from "../../services/logger";

export const useUserSignOut = () => {
  const signOut = async () => {
    Logger.info("signing user out");
    try {
      await firebaseSignOut(auth);
      Logger.success("successfully signed user out");
      sessionStorage.clear();
    } catch (error) {
      Logger.error("failed to sign user out", { error });
    }
  };
  return { signOut };
};
