import './AboutUs.scss';

const AboutUs = () => (
  <div className="about-us">
    <h1 className="plus-jakarta-sans-800">
      About Us
    </h1>
    <p className="plus-jakarta-sans-300">
      In the mystical land where the realms of software development and arcane arts converge, a distinguished council of wizards embarked on a quest. We, the Archmages, Grand Wizards, and High Sorcerers of the Scrum Wizards’ Council, were united by a shared vision: to create a planning poker tool that harmonizes efficiency with enchantment. Frustrated by the lackluster tools that failed to capture the magic needed for our intricate projects, we decided to forge our own path. Thus, <span className="plus-jakarta-sans-700">Scrum Wizardry</span> was born.
    </p>
    <p className="plus-jakarta-sans-300">
      Our journey began in the ancient halls of the Wizards' Academy, where spells and code intertwine seamlessly. Drawing upon centuries of wisdom and the latest in agile methodologies, we combined our powers to craft a platform that is both intuitive and delightful. Scrum Wizardry is more than just a tool; it is an experience designed to make the planning process engaging and effective.
    </p>
    <p className="plus-jakarta-sans-300">
      Our mission is to transform the way teams approach planning poker. We believe that planning should be both productive and enjoyable, filled with the kind of magic that inspires creativity and collaboration. With Scrum Wizardry, we aim to provide a platform that enhances the planning process through:
    </p>
    <ul>
      <li>
        <p>
          <span className="plus-jakarta-sans-500">Intuitive Design: </span>
          Our interface is designed to be user-friendly, ensuring that even the most complex planning sessions are easy to manage.
        </p>
      </li>
      <li>
        <p>
          <span className="plus-jakarta-sans-500">Engaging Features: </span>
          From spellbinding animations to interactive elements, every aspect of Scrum Wizardry is crafted to keep teams engaged and motivated.
        </p>
      </li>
      <li>
        <p>
          <span className="plus-jakarta-sans-500">Seamless Integration: </span>
          Our tool integrates effortlessly with other project management platforms, making it a versatile addition to any workflow.
        </p>
      </li>
      <li>
        <p>
          <span className="plus-jakarta-sans-500">Collaborative Spirit: </span>
          We foster a community where wizards (users) can share tips, tricks, and best practices, enhancing the collective knowledge and skills of all.
        </p>
      </li>
    </ul>
    <p className="plus-jakarta-sans-300">
      We envision a world where agile planning is not a chore but a source of inspiration and teamwork. By infusing a bit of magic into the mundane, Scrum Wizardry helps teams achieve better estimations, improved collaboration, and more successful projects.
    </p>
    <p className="plus-jakarta-sans-300">
      We invite you to join us on this spellbinding journey. Whether you are a seasoned scrum master or new to the agile methodologies, Scrum Wizardry offers the tools and enchantment needed to elevate your planning sessions. Together, let's cast a spell of efficiency and fun on your projects!
    </p>
    <p className="signature plus-jakarta-sans-300">
      <span>By the decree of the Wizards’ Council,</span>
      <span className="small plus-jakarta-sans-700">Team at altMindInc.,</span>
      <span className="small plus-jakarta-sans-700">Council of Archmages, Scrum Wizardry</span>
    </p>
  </div>
);

export default AboutUs;