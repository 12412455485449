import './Teams.scss';

import { motion } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faPeopleGroup, faShareNodes } from '@fortawesome/free-solid-svg-icons'

import AppContext from "../../AppContext";

const Teams = () => {
  const [message, setMessage] = useState('');
  const { teamsOwned, user } = useContext(AppContext);

  const handleInviteTeam = useCallback((e: any) => {
    const teamId = e.currentTarget.dataset.testid;
    console.log(window.location);
    navigator.clipboard.writeText(`${window.location.host}/signup?team=${teamId}`);
    setMessage('Invite link copied to clipboard to share');
  }, []);

  return (
    <motion.div
      initial={{
        x: "70%",
        opacity: 0,
      }}
      className="wrapper"
      animate={{
        x: "0",
        opacity: 1,
      }}
      transition={{ duration: 1, delay: 0.5 }}
    >
      <div className="teams-owned-wrapper">
        {teamsOwned && teamsOwned.length > 0 && (
          <>
            <motion.div
              className="message"
              animate={{ opacity: 1 }}
              transition={{ ease: "linear", duration: 2, x: { duration: 1 } }}
              initial={{ opacity: 0 }}
            >
              {message}
            </motion.div>
            {teamsOwned.map((team: any) => (
              <div className="teams" key={team.teamId}>
                <div className="team">
                  <div className="icon">
                    <FontAwesomeIcon icon={faPeopleGroup} />
                  </div>
                  <div className="name">{team.name}</div>
                  <FontAwesomeIcon icon={faShareNodes} className="share" data-testid={team.teamId} onClick={handleInviteTeam} />
                </div>
                {team.members && team.members.length > 0 && (
                  <div className="members">
                    {team.members.map((member: any) => {
                      const isTeamOwner = member.userId === user.uid && team.isOwner;

                      return (
                        <div className={isTeamOwner ? 'member owner' : 'member'} key={`${member.teamId}-${member.userId}`}>
                          <div className="icon">
                            <FontAwesomeIcon icon={isTeamOwner ? faUser : faUsers} />
                          </div>
                          <div className="name">
                            {member.name}
                            <div className="email">
                              {member.email}
                            </div>
                          </div>
                        </div>
                      )}
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </motion.div>
  );
};

export default Teams;
