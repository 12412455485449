import { useCallback, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';

import { updateUser } from "./api"

interface AddUserDetailsProps {
  setViewType: (view: string) => void;
  teamId?: string;
}

const AddUserDetails =({ setViewType, teamId }: AddUserDetailsProps) => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const { setUser } = useContext(AppContext);

  const handleUserNameChange = useCallback((e: any) => {
    setUserName(e.target.value);
  }, []);

  const handleFormSubmit = useCallback((e: any) => {
    setIsLoading(true);
    e.preventDefault();
    updateUser({ userName, teamId })
    .then(data => {
      setUser(data);
      // check if teamid esists in session storage then make api call to join team and navigate to '/' else set view to team
      
      if (teamId) { 
        navigate("/");
      } else {
        setViewType('team');
      }
      setIsLoading(false);
    })
    .catch(err => { 
      setIsLoading(false);
      console.error(err); 
    });
  }, [setUser, setViewType, userName]);

  return (
    <motion.div
      initial={{
        x: "70%",
        opacity: 0,
      }}
      className="auth-wrapper"
      animate={{
        x: "0",
        opacity: 1,
        height: "100%",
      }}
      transition={{ duration: 1, delay: 0.1 }}
    >
      <h4>What should we call you?</h4>
      <form onSubmit={handleFormSubmit}>
        <input type="text" id="userName" placeholder='Team Name' value={userName} onChange={handleUserNameChange} />
        <button type="submit" className={userName === '' || isLoading ? 'disabled' : ''}>Continue</button>
      </form>
    </motion.div>
  )
};

export default AddUserDetails;
