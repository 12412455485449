import './App.scss';

import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AdSense from "react-adsense";

import { useAuthUser } from './components/Auth/hooks';
import SEO from './components/SEO';
import Footer from './components/Footer';

import { generateUid } from "./utils";

import AppContext from './AppContext';
import { User } from "./types/user";
import { OwnerTeams, Team } from "./types/team";

import Router from './Router';
import Header from './components/Header';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const defaultUser = {
  displayName: '',
  uid: '',
  emailVerified: false,
  email: '',
  phoneNumber: '',
  photoURL: ''
};

const App = () => {
  const [user, setUser] = useState<User>(defaultUser);
  const [currentTeam, setCurrentTeam] = useState<Team>({
    teamId: '',
    name: '',
    teamOwnerId: '',
    createdAt: 0,
    teamMemberId: '',
  });
  const [teamsOwned, setTeamsOwned] = useState<OwnerTeams>([{
    teamId: '',
    name: '',
    isOwner: false,
    isActive: false,
    members: [],
  }]);
  const sessionUserId = sessionStorage.getItem('userId') || generateUid();

  const { user: currentUser, hasLoadedUser } = useAuthUser();

  const userId = user.uid || sessionUserId;

  if (!sessionStorage.getItem('userId')) {
    sessionStorage.setItem('userId', userId);
  }

  useEffect(() => {
    if (hasLoadedUser) {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(defaultUser);
      }
    }
  }, [hasLoadedUser, currentUser]);

  return (
    <QueryClientProvider client={queryClient}>
      <SEO
        title="Scrum Wizardry - Free online planning poker tool"
        description="Scrum wizardry - your one stop shop for all things scrum. This is free tool for Scrum planning poker."
        keywords="scrum, agile, project management, planning, planning poker, poker, scrum master, product owner, team, sprint, user story, task, burndown chart, burnup chart, velocity, estimation, retrospective, sprint review, sprint planning, daily standup, standup, scrum of scrums, agile coach, agile transformation"
        url="https://www.scrumwizardry.com/"
      />
      <AdSense.Google
        client="ca-pub-2624849619110649"
        slot="6193142058"
        style={{ display: 'block' }}
        format='auto'
        responsive='true'
      />
      <AppContext.Provider
        value={{
          userId: userId ?? '',
          setUser,
          user,
          setCurrentTeam,
          currentTeam,
          setTeamsOwned,
          teamsOwned,
        }}
      >
        <div className="app">
          <Header />
          <div className="content-wrapper"><Router /></div>
        </div>
        <Footer />
      </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
};

export default App;
