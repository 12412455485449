import { db } from "../../services/firebase";
import { collection, addDoc, doc,setDoc } from "firebase/firestore";
import { v4 } from "uuid";

import { getTeamDetails } from '../Teams/api';

import Logger from "../../services/logger";

export const addTeam = async ({
  teamName,
  userId,
}: {
  teamName: string;
  userId: string;
}) => {
  try {
    Logger.info("addTeam - creating new team ", {});

    const teamId = v4().replaceAll("-", "").slice(0, 8);

    await setDoc(doc(db, "team", teamId), {
      owner: userId,
      is_active: true,
      name: teamName,
      created_at: Date.now(),
    });
  
    Logger.info("addTeam - new team created ", {
      teamId,
    });

    try {
      const createdAt = Date.now();
      const membersDocumentRef = await addDoc(collection(db, "teamMembers"), {
        user_id: userId,
        team_id: teamId,
        created_at: createdAt,
        isActive: true,
        updated_at: null,
      });

      const teamMemberId = membersDocumentRef.id;

      Logger.info("addTeam - new team member created ", {
        teamMemberId,
      });

      return ({
        teamId,
        name: teamName,
        teamOwnerId: userId,
        createdAt,
        teamMemberId,
      });
    } catch (error: any) {
      console.error("addTeam - failed to create members", error);
      return ({
        status: 500,
        error: error?.message ?? "",
      });
    }
  } catch (e) {
    console.error("addTeam - Error adding document: ", e);
  }
};

export const addTeamMember = async ({
  teamId,
  userId,
}: {
  teamId: string;
  userId: string;
}) => {
  try {
    Logger.info("addTeamMember - adding new member to team ", {
      teamId,
    });

    try {
      const createdAt = Date.now();
      const membersDocumentRef = await addDoc(collection(db, "teamMembers"), {
        user_id: userId,
        team_id: teamId,
        created_at: createdAt,
        isActive: true,
        updated_at: null,
      });

      const teamMemberId = membersDocumentRef.id;

      const teamDetails = await getTeamDetails({ teamId });

      Logger.info("addTeamMember - new team member created ", {
        teamMemberId,
      });

      return ({
        teamId,
        name: teamDetails?.name ?? "",
        teamOwnerId: teamDetails?.owner ?? "",
        createdAt,
        teamMemberId,
      });
    } catch (error: any) {
      console.error("addTeamMember - failed to create members", error);
      return ({
        status: 500,
        error: error?.message ?? "",
      });
    }
  } catch (e) {
    console.error("addTeamMember - Error adding document: ", e);
  }
};
