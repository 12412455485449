import { db } from "../../services/firebase";
import { addDoc, collection } from "firebase/firestore";
import Logger from "../../services/logger";

export const createSubscriber= async ({
  email,
}: {
  email: string;
}) => {
  try {
    Logger.info("creating new subscriber ", { email });

    const subscribersDocumentRef = await addDoc(collection(db, "subscribers"), {
      email: email,
      created_at: Date.now(),
    });

    const subscriberId = subscribersDocumentRef.id;
    
    Logger.info("new subscriber created ", {
      subscriberId,
    });

    return ({
      subscriberId,
    })
    
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};