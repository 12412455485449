import { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { ProtectedRoute } from "./ProtectedRoute";

import AppContext from './AppContext';

import { getTeamsOwned } from "./components/Teams/api";

import PlanningPoker from './components/PlanningPoker';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';
import Auth from './components/Auth';
import NotFound from './components/NotFound';
import ScrollToTop from './ScrollToTop';
import Home from './components/Home';
import Teams from './components/Teams';
import Profile from './components/Profile';

const Router = () => {
  const { user, setTeamsOwned } = useContext(AppContext);

  useEffect(() => {
    if (user.uid) {
      getTeamsOwned({ userId: user.uid })
      .then(data => setTeamsOwned(data))
      .catch(err => console.error(err));
    }
  }, [setTeamsOwned, user.uid]);

  return (
    <AnimatePresence mode="wait">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/signup" element={ <Auth view="register" /> }/>
        <Route path="/login" element={ <Auth view="login" /> }/>
        <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
        <Route path="/terms-conditions" element={ <TermsConditions />} />
        <Route path="/about-us" element={ <AboutUs /> } />
        <Route path="/faq" element={ <FAQ /> } />
        <Route path="/profile" element={ <Profile /> } />
        <Route path="/teams" element={ <ProtectedRoute><Teams /></ProtectedRoute> }/>
        <Route path="/planning-poker" element={ <PlanningPoker /> }>
          <Route path=":roomId" element={<PlanningPoker />} />
        </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </AnimatePresence>
  );
};

export default Router;
