import { createUserWithEmailAndPassword } from "firebase/auth";

import { doc, setDoc } from "firebase/firestore";

import { User } from "../../types/user";
import { auth, db } from "../../services/firebase";
import Logger from "../../services/logger";

export const emailRegister = async ({
  userEmail, userPassword,
}: {
  userEmail: string;
  userPassword: string;
}) => {
  Logger.info("creating user with email ", {
    userEmail
  });

  try {
    const user = await createUserWithEmailAndPassword(auth, userEmail, userPassword);

    await setDoc(doc(db, "users", user.user.uid), {
      email: userEmail,
      created_at: Date.now(),
    });
  
    Logger.info("new user created ", {
      userId: user.user.uid,
    });

    sessionStorage.clear();

    sessionStorage.setItem('userId', user.user.uid);

    const userObj: User = {
      displayName: user.user.displayName || '',
      uid: user.user.uid,
      emailVerified: user.user.emailVerified,
      email: user.user.email || '',
      phoneNumber: user.user.phoneNumber || '',
      photoURL: user.user.photoURL || '',
    };

    return (userObj);
  } catch (error: any) {
    const userObj: User = {
      displayName: '',
      uid: '',
      emailVerified: false,
      email: '',
      phoneNumber: '',
      photoURL: '',
    };
    return (userObj);
  }
};