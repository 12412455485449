import { useCallback, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import AppContext from '../../AppContext';

import { emailSignIn } from "./api";

interface LoginProps {
  setViewType: (view: string) => void;
}

const Login =({ setViewType }: LoginProps) => {
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { user, setUser } = useContext(AppContext);

  const handleEmailChange = useCallback((e: any) => {
    setUserEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: any) => {
    setUserPassword(e.target.value);
  }, []);

  const handleFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    setIsLoading(true);

    emailSignIn({ userEmail, userPassword})
    .then((data) => {
      setIsLoading(false);
      if (data.uid) {
        setUser(data);
        navigate('/');
      } else {
        setErrorMessage('Something went wrong. Please check your credentials and try again');
      }
    })
    .catch(err => {
      console.error(err);
      setIsLoading(false);
    });
  }, [navigate, setUser, userEmail, userPassword]);

  useEffect(() => {
    if (user.uid) {
      navigate('/');
    }
  }, [navigate, user.uid]);

  const handleSignUpClick = useCallback((e: any) => {
    e.preventDefault();
    setViewType('register');
  }, [setViewType]);

  return (
    <motion.div
      initial={{
        x: "70%",
        opacity: 0,
      }}
      className="auth-wrapper"
      animate={{
        x: "0",
        opacity: 1,
        height: "100%",
      }}
      transition={{ duration: 1, delay: 0.1 }}
    >
      <h4>Enter the realm where Scrum magic happens</h4>
      <form onSubmit={handleFormSubmit}>
        <div className="error">{errorMessage}</div>
        <input type="email" id="email" placeholder='Email' value={userEmail} onChange={handleEmailChange} />
        {userEmail && <input type="password" id="password" placeholder='Password' value={userPassword} onChange={handlePasswordChange} />}
        <button type="submit" className={userEmail === '' || userPassword === '' || isLoading ? 'disabled' : ''}>Login</button>
      </form>
      <div>
        <p>Not a member yet?</p>
         <button className="link" onClick={handleSignUpClick}>
            Sign up to start your journey
            <FontAwesomeIcon icon={faArrowRight} style={{ color: "#fff", marginLeft: "5px", fontSize: "12px" }} />
         </button>
      </div>
    </motion.div>
  )
};

export default Login;
