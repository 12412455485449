import { useQuery } from "@tanstack/react-query";
import { addTeam } from "./api";
import { Team } from "../../types/team";

export const useAddTeam = ({
  teamName,
  userId,
  hasSubmittedTeam
}: {
  teamName: string;
  userId: string;
  hasSubmittedTeam: boolean;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ["addTeam'", teamName],
    queryFn: () => addTeam({ teamName, userId }),
    enabled: hasSubmittedTeam,
  }) as { data: Team, isLoading: boolean };

  return {
    data,
    isLoading,
  };
};
