import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const SparklingStars = () => (
  <>
    <motion.div
          initial={{ scale: "0.6", left: "10%", opacity: 0.2, position: "absolute", top: "10%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.4,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#f4ec17", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "25%", opacity: 0.2, position: "absolute", top: "2%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "90%", opacity: 0.2, position: "absolute", top: "80%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "40%", opacity: 0.2, position: "absolute", top: "60%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "10%", opacity: 0.2, position: "absolute", top: "45%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "15%", opacity: 0.2, position: "absolute", top: "75%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "85%", opacity: 0.2, position: "absolute", top: "55%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.6,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "90%", opacity: 0.2, position: "absolute", top: "15%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.4,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "40%", opacity: 0.2, position: "absolute", top: "16%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.1,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "80%", opacity: 0.2, position: "absolute", top: "5%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.1,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "45%", opacity: 0.2, position: "absolute", top: "5%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.2,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
        <motion.div
          initial={{ scale: "0.6", left: "65%", opacity: 0.2, position: "absolute", top: "15%" }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.2,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
        </motion.div>
  </>
);

export default SparklingStars;
