import './Footer.scss';

import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { createSubscriber } from './api';

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;

  const [currentScreen, setCurrentScreen] = useState(pathname.split("/")[1]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentScreen(pathname.split("/")[1]);
  }, [pathname]);
  
  const handleNavClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    const path = e.currentTarget.dataset.id || '';
    setCurrentScreen(path);

    navigate(`/${path}`);
  }, [navigate]);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handleSubscribe = useCallback(() => {
    createSubscriber({ email }).then(() => {
      setMessage('You have successfully subscribed to our newsletter');

      setTimeout(() => setMessage(''), 5000);
    });
  }, [email]);

  return (
    <div className="main-footer">
      <div className="top-row">
        <div className="left-content">
          <button 
            onClick={handleNavClick}
            data-id="privacy-policy"
            className={currentScreen === 'privacy-policy' ? 'selected' : ''}
          >
            Privacy Policy
          </button>
          <button 
            onClick={handleNavClick}
            data-id="terms-conditions"
            className={currentScreen === 'terms-conditions' ? 'selected' : ''}
          >
            Terms & Conditions
          </button>
          <button 
            onClick={handleNavClick}
            data-id="about-us"
            className={currentScreen === 'about-us' ? 'selected' : ''}
          >
            About Us
          </button>
          {/* <button 
            onClick={handleNavClick}
            data-id="faq"
            className={currentScreen === 'faq' ? 'selected' : ''}
          >
            FAQ
          </button> */}
        </div>
        <div className="middle-content">
          <button 
            onClick={handleNavClick}
            data-id="planning-poker"
            className={currentScreen === 'planning-poker' ? 'selected' : ''}
          >
            Planning Poker
          </button>
        </div>
        <div className="right-content">
          <div className="subscribe">
            <input type="text" name="email" onChange={handleEmailChange} />
            <button className="footer-btn" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
          <p>{message}</p>
        </div>
      </div>
      <div className="copyright">&copy; {(new Date().getFullYear())} Scrum Wizardry. All rights reserved.</div>
      
    </div>
  );
};

export default Footer;
