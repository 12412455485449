import { db } from "../../services/firebase";
import { collection, getDoc, doc, where, query, getDocs } from "firebase/firestore";
import Logger from "../../services/logger";
import { getUserName } from '../Auth/api';

export const getTeamDetails = async ({
  teamId,
}: {
  teamId: string;
}) => {
  try {
    Logger.info('getTeamDetails - Fetching team details for ', { teamId });

    const teamDocument = await getDoc(doc(db, 'team', teamId));

    Logger.info("getTeamDetails - teamDocument ", { teamDocument });

    if (teamDocument.exists()) {
      return ({
        teamId: teamId,
        ...teamDocument.data(),
      });
    }
    else {
      Logger.info("getTeamDetails - no user found ", {});
      return ({
        status: 404,
        error: "Team not found",
        name: '',
        owner: '',
      });
    }

  } catch (e) {
    console.error("getTeamDetails - Error adding document: ", e);
  }
};

export const getTeamsOwned = async ({
  userId,
}: {
  userId: string;
}) => {
  try {
    Logger.info('getTeamsOwned - Fetching teams owned by user ', { userId });

    const ownerTeamRef = query(collection(db, "team"), where("owner", "==", userId));
    const ownerTeamQuerySnapshot = await getDocs(ownerTeamRef);

    let teamsOwned: any = [];

    ownerTeamQuerySnapshot.forEach(async (team) => {
      Logger.info("getTeamsOwned - team owned by user ", { team: team.data() });

      const teamMembers = await getTeamMembers({ teamId: team.id });

      teamsOwned.push({
        teamId: team.id,
        name: team.data().name,
        isActive: team.data().is_active,
        isOwner: team.data().owner === userId,
        members: teamMembers,
      });
    });

    Logger.info("getTeamsOwned - final record before return ", { members: teamsOwned });

    return (teamsOwned);

  } catch (e) {
    console.error("getTeamsOwned - Error adding document: ", e);
  }
};

export const getTeamMembers = async ({
  teamId,
}: {
  teamId: string;
}) => {
  try {
    Logger.info("getTeamMembers - fetching team members ", { teamId });

    const teamMembersRef = query(collection(db, "teamMembers"), where('team_id', '==', teamId));
    const querySnapshot = await getDocs(teamMembersRef);

    let members: any = [];

    querySnapshot.forEach(async (member) => {
      Logger.info("getTeamMembers - record in teamMembers ", { member: member.data() });

      const userId = member.data().user_id;

      const userDetails = await getUserName({ userId }) || {};

      members.push({
        teamId: member.data().team_id,
        userId: member.data().user_id,
        ...userDetails,
      });
    });

    Logger.info("getTeamMembers - final record before return ", { members: members });

    return (members);

  } catch (e) {
    console.error("getTeamMembers - Error adding document: ", e);
  }
};

export const getTeams = async ({
  userId,
}: {
  userId: string;
}) => {
  try {
    Logger.info("getTeams - fetching user teams ", { userId });

    const queryConstraints = [];
    queryConstraints.push(where('user_id', '==', userId));
    queryConstraints.push(where('isActive', '==', true));

    const currentUserTeamRef = query(collection(db, "teamMembers"), ...queryConstraints);
    const currentUserTeamQuerySnapshot = await getDocs(currentUserTeamRef);

    let teams: any = [];

    currentUserTeamQuerySnapshot.forEach(async (team) => {
      Logger.info("getTeams - current user team mmeber record ", { team: team.data() });

      //Get team details friom team collection using this team id
      const teamDocument = await getDoc(doc(db, 'team', team.data().team_id));
      
      if (teamDocument.exists()) {
        Logger.info("getTeams - current user team details found ", { teamDetails: teamDocument.data() });
        const isActive = teamDocument.data().is_active;
        const owner = teamDocument.data().owner;
        const name = teamDocument.data().name;

        teams.push({
          teamId: team.data().team_id,
          isActive: isActive,
          name: name,
          isOwner: owner === userId,
        })
      }

      Logger.info("getTeams - final return array  ", { teams });
    });

    return (await Promise.all(teams));
  } catch (e) {
    console.error("getTeams - Error adding document: ", e);
  }
};
