import React, { useCallback, useEffect, useState} from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';

import { createRoom, getMembersInRoom, joinRoom } from './api';

type HeaderProps = {
  setRoomDetails: (value: {
    roomId: string;
    isRoomActive: boolean;
    roomOwnerId: string;
    memberId: string;
    showEstimates: boolean;
  }) => void;
  roomDetails: {
    roomId: string;
    isRoomActive: boolean;
    roomOwnerId: string;
    memberId: string;
    showEstimates: boolean;
  };
  userId: string;
  sharedRoomId: string;
  setMembersInRoom: (value: any) => void;
};

const Header: React.FC<HeaderProps> = React.memo(({ roomDetails, setRoomDetails, userId, sharedRoomId, setMembersInRoom }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [creatingRoom, setCreatingRoom] = useState(false);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [completedAnimation, setCompletedAnimation] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  
  const getMembersInRoomById = useCallback((value: string) => {
    if (value !== '') {
      getMembersInRoom({ roomId: value }).then((response) => {
        if (response?.error) setErrorMessage(response?.error);

        setMembersInRoom(response);
      });
    }
  }, [setMembersInRoom]);

  useEffect(() => {
    if (sharedRoomId !== '' && !joiningRoom) {
      setJoiningRoom(true);
      joinRoom({ roomId: sharedRoomId, userId }).then((response) => {
        if (response?.error) setErrorMessage(response?.error);
        if (response?.roomId) setRoomDetails({
          roomId: response?.roomId,
          isRoomActive: response?.isActive,
          roomOwnerId: response?.roomOwnerId,
          memberId: response?.memberId,
          showEstimates: response?.showEstimates,
        });

        if (response?.roomId && response?.isActive) getMembersInRoomById(response?.roomId);
      }).finally(() => {
        setJoiningRoom(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShareRoom = useCallback(() => {
    navigator.clipboard.writeText(`${window.location.origin}/planning-poker/${roomDetails.roomId}`);
    setMessage('Room link copied to clipboard to share');
  }, [roomDetails.roomId]);

  const handleCreateRoom = useCallback(() => {
    setCreatingRoom(true);
    createRoom({ userId }).then((response) => {
      if (response?.error) setErrorMessage(response?.error);
      if (response?.roomId) setRoomDetails({
        roomId: response?.roomId,
        isRoomActive: response?.isActive,
        roomOwnerId: response?.roomOwnerId,
        memberId: response?.memberId,
        showEstimates: response?.showEstimates,
      });

      if (response?.roomId && response?.isActive) getMembersInRoomById(response?.roomId);

    }).finally(() => {
      setCreatingRoom(false);
    });
  }, [getMembersInRoomById, setRoomDetails, userId]);

  return (
    <>
      {(roomDetails.roomId === '' || !roomDetails.isRoomActive) ? (
        <>
          <div className="text plus-jakarta-sans-400">Start a battefield</div>
          <motion.button
            whileHover={{
              scale: 1.1,
              backgroundColor: "#0171C2"
            }}
            transition={{ duration: 0.5, yoyo: Infinity }}
            onClick={handleCreateRoom}
            className={`Button submit-btn`}
          >
            Create battlefield
          </motion.button>
        </>
      ): (
        <>
          <motion.div
            className="message"
            animate={{ opacity: 1 }}
            transition={{ ease: "linear", duration: 2, x: { duration: 1 } }}
            initial={{ opacity: 0 }}
          >
            {message}
          </motion.div>
      
          <motion.h1
            animate={{ x: [50, 150, 0], opacity: 1, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0.1,
              ease: [0.5, 0.71, 1, 1.5],
            }}
            onAnimationComplete={() => setCompletedAnimation(true)}
            initial={{ opacity: 0, scale: 0.5, position: "relative", width: "100%", color: "#3D4766" }}
          >
            You are in battlefield: {roomDetails.roomId}
            {completedAnimation && (
              <FontAwesomeIcon icon={faShareNodes} className="share" onClick={handleShareRoom} />
            )}
          </motion.h1>
        </>
      )}
      <div>{errorMessage}</div>
      {(creatingRoom || joiningRoom) && (
        <div className="loading">Battlefield is being setup...</div>
      )}
    </>
  );
});

export default Header;
