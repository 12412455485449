import "./Nav.scss";

import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';

import { useUserSignOut } from "./hooks";

const Nav = () => {
  const location = useLocation();
  const { pathname } = location;

  const { user } = useContext(AppContext);

  const [currentScreen, setCurrentScreen] = useState(pathname.split("/")[1]);
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentScreen(pathname.split("/")[1]);
  }, [pathname]);

  const { signOut } = useUserSignOut();

  const handleNavClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    const path = e.currentTarget.dataset.id || '';
    setCurrentScreen(path);

    if (path !== "sign-out") {
      navigate(`/${path}`)
    } else {
      signOut();
      navigate('/login');
    };
  }, [navigate, signOut]);
  
  return (
    <div className="nav-header">
      <div className="logo-nav">
        <ul className={click ? "nav-options active" : "nav-options"}>
          <li className="option" onClick={closeMobileMenu}>
            <button 
              onClick={handleNavClick}
              data-id=""
              className={currentScreen === '' ? 'selected' : ''}
            >
              Home
            </button>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <button 
              onClick={handleNavClick}
              data-id="planning-poker"
              className={currentScreen === 'planning-poker' ? 'selected' : ''}
            >
              Planning Poker
            </button>
          </li>
          {/* {user.uid ? (
            <>
              <li className="option" onClick={closeMobileMenu}>
                <button 
                  onClick={handleNavClick}
                  data-id="profile"
                  className={currentScreen === 'profile' ? 'selected' : ''}
                >
                  Profile
                </button>
              </li>
              <li className="option" onClick={closeMobileMenu}>
                <button 
                  onClick={handleNavClick}
                  data-id="sign-out"
                  className={currentScreen === 'sign-out' ? 'selected' : ''}
                >
                  Log out
                </button>
              </li>
            </>
          ): (
            <li className="option" onClick={closeMobileMenu}>
            <button 
              onClick={handleNavClick}
              data-id="login"
              className={currentScreen === 'login' ? 'selected' : ''}
            >
              Login
            </button>
          </li>
          )} */}
        </ul>
      </div>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <FontAwesomeIcon icon={faXmark} className="menu-icon" />
        ) : (
          <FontAwesomeIcon icon={faBars} className="menu-icon" />
        )}
      </div>
    </div>
  );
};

export default Nav;
