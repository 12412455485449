import './Wrapper.scss';

import { useContext, useEffect, useState} from 'react';
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { collection, where, query, onSnapshot, documentId } from "firebase/firestore";

import { db } from "../../services/firebase";


import AppContext from '../../AppContext';

import PlanningPoker from './PlanningPoker';
import Header from './Header';

import { checkMembersInRoom } from './api';

const Wrapper = () => {
  const { userId, user } = useContext(AppContext);
  
  const { roomId: sharedRoomId = '' } = useParams();
  
  if (sharedRoomId !== '') sessionStorage.setItem('roomId', sharedRoomId);

  const [roomDetails, setRoomDetails] = useState({
    roomId: '',
    isRoomActive: false,
    roomOwnerId: '',
    memberId: '',
    showEstimates: false,
  });
  
  const [membersInRoom, setMembersInRoom] = useState([
    {
      memberId: '',
      roomId: '',
      estimate: '',
      userId: '',
    }
  ]);

  useEffect(() => {
    if (roomDetails.roomId !== "") {
      const q = query(collection(db, "rooms"), where(documentId(), '==', roomDetails.roomId));
      const unsubscribe = onSnapshot(q, async (snapshot: { docs: any[]; }) => {
        const updatedData = snapshot.docs.map(async (doc) => {
          const memberId = await checkMembersInRoom({ userId, roomId: roomDetails.roomId }).then((response) => response.memberId);
          return ({
            roomId: roomDetails.roomId,
            isRoomActive: doc.data().is_active,
            roomOwnerId: doc.data().owner,
            memberId: await memberId,
            showEstimates: doc.data().show_estimates,
          });
        });
        setRoomDetails(await updatedData[0]);
      });

      // Cleanup function to unsubscribe when component unmounts
      return () => unsubscribe();
    }
  }, [setRoomDetails, roomDetails.roomId, userId]);

  return (
    <>
      <motion.div
        initial={{
          x: "70%",
          opacity: 0,
        }}
        className="wrapper"
        animate={{
          x: "0",
          opacity: 1,
        }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <Header
          roomDetails={roomDetails}
          setRoomDetails={setRoomDetails}
          userId={userId}
          sharedRoomId={sessionStorage.getItem('roomId') || ''}
          setMembersInRoom={setMembersInRoom}

        />
        <PlanningPoker
          setMembersInRoom={setMembersInRoom}
          roomDetails={roomDetails}
          membersInRoom={membersInRoom}
        />
      </motion.div>
      {/* <div className="footer-tab">
        <Tab />
      </div> */}
    </>
  )

};

export default Wrapper;
