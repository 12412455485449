import './Header.scss';
import logo from '../../assets/logo_latest.png';
import Nav from '../Nav';

const Header = () => (
  <div className="header">
    <a href="/"><img src={logo} className="logo" alt="logo" /></a>
    <Nav />
  </div>
);

export default Header;
