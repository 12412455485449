import './Auth.scss';

import { useState } from 'react';
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";

import SparklingStars from '../SparklingStars';
import Register from '../Register';
import Login from '../Login';
import AddTeam from '../AddTeam';
import AddUserDetails from '../AddUserDetails';

interface AuthProps {
  view: string;
  subHeader?: string;
}

const Auth = ({ view, subHeader }: AuthProps) => {
  const [viewType, setViewType] = useState(view);
  const [ searchParams ] = useSearchParams() || '';

  const teamId = searchParams.get("team") || '';

  return (
    <motion.div
      initial={{
        x: "70%",
        opacity: 0,
      }}
      className="auth"
      animate={{
        x: "0",
        opacity: 1,
        height: "100%",
      }}
      transition={{ duration: 1, delay: 0.1 }}
    >
      <div className="content">
        <h1>Welcome to Scrum Wizardry</h1>
        {subHeader && <h4>{subHeader}</h4>}
        <div className="form">
          <SparklingStars />
          {viewType === "register" && <Register setViewType={setViewType} /> }
          {viewType === "login" && <Login setViewType={setViewType} /> }
          {viewType === "addUserDetails" && <AddUserDetails setViewType={setViewType} teamId={teamId} />}
          {viewType === "team" && <AddTeam />}
        </div>
      </div>
    </motion.div>
  );
};

export default Auth;
