import './TermsConditions.scss';

const TermsConditions = () => (
  <div className="terms-conditions">
    <h1 className="plus-jakarta-sans-800">
      Terms & Conditions
    </h1>
    <p className="plus-jakarta-sans-300">
      Thank you for choosing ScrumWizardy! By accessing or using our website and services, you agree to abide by the following Terms and Conditions ("Terms"). These terms are here to ensure a safe, enjoyable, and productive experience for everyone. If you don't agree with any part of these Terms, we kindly ask you not to use our services.
    </p>
    <div className="terms-wrapper">
      <h3 className="plus-jakarta-sans-500">Who Can Use Our Services</h3>
      <ul>
        <li>
          <p>
            <span className="plus-jakarta-sans-700">Eligibility: </span>
            You must be at least 18 years old to use ScrumWizardy. By accessing our website, you confirm that you meet this age requirement.
          </p>
        </li>
        <li>
          <p>
            <span className="plus-jakarta-sans-700">Creating an Account: </span>
            To get the most out of ScrumWizardy, you might need to create an account. Please provide accurate and up-to-date information, and keep it updated. You're responsible for safeguarding your account details and all activities under your account.
          </p>
        </li>
        <li>
          <p>
            <span className="plus-jakarta-sans-700">What You Shouldn't Do: </span>
            We want everyone to enjoy ScrumWizardy, so please:
          </p>
          <ol>
            <li>
              Don't use our services for any illegal activities.
            </li>
            <li>
              Follow all applicable laws and regulations.
            </li>
            <li>
              Avoid doing anything that could harm or disrupt our website.
            </li>
            <li>
              Don't try to access parts of our website that you're not authorized to.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <span className="plus-jakarta-sans-700">Collaborative Spirit: </span>
            We foster a community where wizards (users) can share tips, tricks, and best practices, enhancing the collective knowledge and skills of all.
          </p>
        </li>
      </ul>
      <h3 className="plus-jakarta-sans-500">Our Intellectual Property</h3>
      <p className="plus-jakarta-sans-300">
        All the content you see on ScrumWizardy – including text, graphics, logos, images, and software – belongs to us or our licensors. Please respect our intellectual property and don't use it without our permission.
      </p>
      <h3 className="plus-jakarta-sans-500">Your Privacy Matters</h3>
      <p className="plus-jakarta-sans-300">
        Your privacy is important to us. Our <a href="/privacy-policy">Privacy Policy</a> explains how we handle your personal information. By using ScrumWizardy, you agree to our Privacy Policy.
      </p>
      <h3 className="plus-jakarta-sans-500">Limitation of Liability</h3>
      <p className="plus-jakarta-sans-300">
        We do our best to make ScrumWizardy awesome, but we can't be responsible for certain things, such as:
      </p>
      <ul>
        <li>
          Any indirect, incidental, special, consequential, or punitive damages.
        </li>
        <li>
          Loss of profits, data, use, goodwill, or other intangible losses.
        </li>
        <li>
          Unauthorized access to or use of our servers and your personal information.
        </li>
        <li>
          Any bugs, viruses, or harmful content transmitted to or through our website by third parties.
        </li>
        <li>
          Errors or omissions in any content or for any loss or damage resulting from using any content posted, emailed, or otherwise made available through our site.
        </li>
      </ul>
      <h3 className="plus-jakarta-sans-500">Your Responsibilities</h3>
      <p className="plus-jakarta-sans-300">
        By using ScrumWizardy, you agree to defend, indemnify, and hold us harmless from any claims, liabilities, damages, losses, and expenses (including legal fees) arising from your use of our website or violation of these Terms.
      </p>
      <h3 className="plus-jakarta-sans-500">Ending Our Relationship</h3>
      <p className="plus-jakarta-sans-300">
        We hope it doesn't come to this, but we reserve the right to suspend or terminate your access to ScrumWizardy if you violate these Terms. We'll do our best to notify you in advance.
      </p>
      <h3 className="plus-jakarta-sans-500">Governing Law</h3>
      <p className="plus-jakarta-sans-300">
        These Terms are governed by the laws of the jurisdiction where ScrumWizardy operates, without regard to conflict of law principles.
      </p>
      <h3 className="plus-jakarta-sans-500">Changes to These Terms</h3>
      <p className="plus-jakarta-sans-300">
        We may update these Terms from time to time. If the changes are significant, we'll let you know at least 30 days before they take effect. Your continued use of ScrumWizardy means you accept these changes.
      </p>
      <h3 className="plus-jakarta-sans-500">Get in Touch</h3>
      <p className="plus-jakarta-sans-300">
        Have questions or need help? We're here for you! Contact us at: <a href="mailto:scrumwizardry@gmail.com">scrumwizardry@gmail.com</a>
      </p>
    </div>
  </div>
);

export default TermsConditions;