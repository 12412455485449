import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="not-found">
    <h1 className="plus-jakarta-sans-800">
      404 - Page Not Found
    </h1>
    <p className="plus-jakarta-sans-300">
      Oops! The page you are looking for might have been removed or is temporarily unavailable
    </p>
    <p className="plus-jakarta-sans-300">
      If you still can't find what you're looking for, feel free to contact us at <a href="mailto:scrumwizardry@gmail.com">scrumwizardry@gmail.com</a>.
    </p>
    <Link to="/">Return to home</Link>
  </div>
);

export default NotFound;
