import './Home.scss';

import { useContext, useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles } from '@fortawesome/free-solid-svg-icons';

import AppContext from '../../AppContext';

const Home = () => {
  const { user } = useContext(AppContext);
  const [showDemo, setShowDemo] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowDemo(true), 5000)
  }, []);

  return (
    <motion.div
      initial={{
        y: "70%",
        opacity: 0,
      }}
      className="home"
      animate={{
        y: "0",
        opacity: 1,
        height: "100%",
      }}
      transition={{ duration: 1, delay: 0.5 }}
    >
      <div className="content">
        <div className="header-div">
          <h1>
            <span className="plus-jakarta-sans-500">Simplify your planning poker setup and start estimating story points effortlessly with&nbsp;</span>
            <span className="plus-jakarta-sans-700 primary">Scrum Wizardry</span>
          </h1>
        </div>
        <p>
          Welcome to <span className="plus-jakarta-sans-800">Scrum Wizardry</span>, a game-changing platform designed to simplify all your Scrum tasks with ease! Whether you're a seasoned Scrum enthusiast or just starting out, our website is here to make your Agile journey smoother than ever.
        </p>
        
        <p>
          In today's fast-paced world, being agile is key, and Scrum is at the forefront of fostering collaboration and innovation. But let's face it, managing all those Scrum processes can get overwhelming. That's where we step in.
        </p>
        
        <p>
          Our platform makes planning poker simple and efficient for your team. The seamless integration of agile principles and magical elements enhances team collaboration, streamlines planning processes, and fosters accurate estimations, ultimately enabling teams to achieve their project objectives with agility and precision.
        </p>

        {/* <p>
          Our platform is like your trusty sidekick for all things Scrum. From planning sprints to those daily stand-ups, we've got your back. No more juggling spreadsheets or drowning in email chains. With our user-friendly interface and powerful features, you'll breeze through your Scrum workflow.
        </p> */}
        
        <p>
          Whether you're a tiny startup or a big player, our platform grows with you. Collaborate effortlessly with your team, keep tabs on progress in real-time, and glean insights to keep improving. Our mission is simple: to help you focus on what truly matters—delighting your customers.
        </p>
        
        <p>
          Join the wave of teams worldwide who've embraced our platform and discovered the joy of hassle-free Scrum management. Welcome to the future of Agile collaboration. Welcome to our platform!
        </p>

        <div className="how-works">
            <h2 className="plus-jakarta-sans-700 h3">How It Works</h2>

              <motion.div
                initial={{
                  x: "70%",
                  opacity: 0,
                }}
                animate={{
                  x: "0",
                  opacity: 1,
                }}
                transition={{ duration: 1 }}
                style={{ width: '100%' }}
              >
                <div style={{ position: 'relative', paddingBottom: 'calc(51.05820105820106% + 41px)', height: 0, width: '100%;'}}>
                  <iframe 
                    src="https://demo.arcade.software/o5DduILxXtVzuYx0DDxb?embed&show_copy_link=true"
                    title="Scrum Wizardy"
                    loading="lazy" 
                    allow="clipboard-write"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light', border: 0 }} />
                </div>
              </motion.div>
            <h4 className="plus-jakarta-sans-500 h4">Follow these easy steps to start estimating your story points:</h4>

            <div className="steps">
              <div> 
                <p className="sub-header plus-jakarta-sans-500">
                  <FontAwesomeIcon icon={faWandSparkles} style={{ color: "#0171C2", marginRight: "5px", fontSize: "12px" }} />
                  Create a Battlefield:
                </p>
                <p>Invoke the "Create a Battlefield" spell to commence your session. A unique, enchanted link to the room will be generated for you.</p>
              </div>
              <div>
                <p className="sub-header plus-jakarta-sans-500">
                  <FontAwesomeIcon icon={faWandSparkles} style={{ color: "#0171C2", marginRight: "5px", fontSize: "12px" }} />
                  Share the Link:
                </p>
                <p>Share this magical link with your team of wizards via email, chat, or other communication channels. Team members join by clicking the enchanted link.</p>
              </div>
              <div>
                <p className="sub-header plus-jakarta-sans-500">
                  <FontAwesomeIcon icon={faWandSparkles} style={{ color: "#0171C2", marginRight: "5px", fontSize: "12px" }} />
                  Select Your Cards:
                </p>
                <p>Within the room, each participant will have access to a deck of planning poker cards. Team members select their estimates for the given user story or task, as if choosing the perfect spell.</p>
              </div>
              <div>
                <p className="sub-header plus-jakarta-sans-500">
                  <FontAwesomeIcon icon={faWandSparkles} style={{ color: "#0171C2", marginRight: "5px", fontSize: "12px" }} />
                  Reveal the Estimates:
                </p>
                <p>Once all wizards have selected their cards, the room owner can reveal the estimates like lifting a magical veil. This promotes a discussion to align on a final estimate, ensuring the magic of consensus is achieved.</p>
              </div>
              <div>
                <p className="sub-header plus-jakarta-sans-500">
                  <FontAwesomeIcon icon={faWandSparkles} style={{ color: "#0171C2", marginRight: "5px", fontSize: "12px" }} />
                  Delete Estimates:
                </p>
                <p>After successfully completing a story point, Scrum Wizardy makes it easy to prepare for the next task by allowing you to delete the previous estimates. This ensures a clean slate for the new story. Use the "Delete Estimates" option to remove all the old estimates, enabling the team to focus on the upcoming story with clarity and precision. By resetting the estimates, Scrum Wizardy helps maintain an organized workflow, allowing the team to seamlessly transition from one task to the next without any leftover data cluttering the planning process.</p>
              </div>
            </div>
            
            <p className="footer">Start your <a href="/planning-poker">planning poker</a> session with Scrum Wizardry today and experience a seamless way to estimate story points with your team!</p>
          </div>
      </div>
    </motion.div>
  )
};

export default Home;
