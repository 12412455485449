// src/ChatGPT.tsx
import React, { useState } from 'react';
import axios from 'axios';

const ChatGPT: React.FC = () => {
  const [input, setInput] = useState<string>('');
  const [output, setOutput] = useState<string>('');

  const handleSubmit = async () => {
    if (!input.trim()) return;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/completions',
        {
          model: 'gpt-3.5-turbo-0125',
          prompt: input,
          max_tokens: 150,
          temperature: 0.7,
          n: 1,
          stop: '\n',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer sk-proj-ppKDwIcgrWwIzbyshVC3T3BlbkFJS3l6dNSN0tjqVp8tBaPz`,
          },
        }
      );

      setOutput(response.data.choices[0].text.trim());
    } catch (error) {
      console.error('Error:', error);
      setOutput('Sorry, I encountered an error.');
    }
  };

  return (
    <div>
      <h1>Chat with ChatGPT</h1>
      <div>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button onClick={handleSubmit}>Send</button>
      </div>
      <div>
        <p>{output}</p>
      </div>
    </div>
  );
};

export default ChatGPT;
