import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { User } from "../../types/user";
import { auth } from "../../services/firebase";
import Logger from "../../services/logger";

export const emailSignIn = async ({
  userEmail, userPassword,
}: {
  userEmail: string;
  userPassword: string;
}) => {
  Logger.info("emailSignIn - logging in user ", { userEmail });

  try {
    const user = await signInWithEmailAndPassword(auth, userEmail, userPassword);

    sessionStorage.clear();

    sessionStorage.setItem('userId', user.user.uid);

    const userObj: User = {
      displayName: user.user.displayName || '',
      uid: user.user.uid,
      emailVerified: user.user.emailVerified,
      email: user.user.email || '',
      phoneNumber: user.user.phoneNumber || '',
      photoURL: user.user.photoURL || '',
    };

    return (userObj);
  } catch (error: any) {
    Logger.info("emailSignIn - error occurred ", { error });
    const userObj: User = {
      displayName: '',
      uid: '',
      emailVerified: false,
      email: '',
      phoneNumber: '',
      photoURL: '',
    };
    return (userObj);
  }
};

export const userSignOut = async () => {
  Logger.info("userSignOut - log out user ");
  signOut(auth).then(() => {
    window.location.href = "/";
  }).catch((error) => {
    Logger.info("userSignOut - error occurred ", { error });
  });
}
