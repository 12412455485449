import './Cards.scss';

import { useCallback, useEffect, useState } from 'react';
import { motion } from "framer-motion";

import { updateEstimates } from './api';

const cardValues = ['?', '0', '0.5', '1', '2', '3', '5', '8', '13', '20', '40', '100', '∞'];

const Cards = ({ userSelectedCard, memberId, roomId }: { userSelectedCard: string, memberId: string, roomId: string }) => {
  const [selectedCard, setSelectedCard] = useState(userSelectedCard);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => setSelectedCard(userSelectedCard), [userSelectedCard]);

  useEffect(() => setErrorMessage(''), [roomId]);

  const handleCardClick = useCallback((e: any) => {
    if (roomId === '') setErrorMessage('Please generate/join a room to be able to select a card');
    updateEstimates({ memberId, estimate: e.target.innerText })
  }, [memberId, roomId]);

  return (
    <>
      <div className="error">{errorMessage}</div>
      <div className="cards">
      {cardValues.map((cardValue) => (
        <motion.div
          whileHover={{
            scale: 1.1,
            borderRadius: "10px"
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
          key={cardValue}
          className="testimotionals"
        >
          <div className={`plus-jakarta-sans-700 card ${selectedCard === cardValue ? 'selected' : ''}`} onClick={handleCardClick}>
            <div className="layer" />
            <div className="card-content">{cardValue}</div>
          </div>
        </motion.div>
      ))}
    </div>
    </>
  );
};

export default Cards;
