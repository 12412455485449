import { db } from "../../services/firebase";
import { collection, addDoc, getDoc, doc,setDoc, where, query, getDocs } from "firebase/firestore";
import { v4 } from "uuid";
import Logger from "../../services/logger";

export const createRoom = async ({
  userId,
}: {
  userId: string;
}) => {
  try {
    Logger.info("creating new room ", {});

    const roomId = v4().replaceAll("-", "").slice(0, 8);

    await setDoc(doc(db, "rooms", roomId), {
      owner: userId,
      is_active: true,
      show_estimates: false,
      created_at: Date.now(),
    });
  
    Logger.info("new room created ", {
      roomId,
    });

    const {
      memberId,
      estimate,
    } = await createRoomMember({ roomId, userId });

    return ({
      roomId,
      userId,
      memberId,
      roomOwnerId: userId,
      estimate,
      isActive: true,
      showEstimates: false,
      error: '',
    })
    
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const checkMembersInRoom = async ({
  roomId,
  userId,
}: {
  roomId: string,
  userId: string,
}) => {
  Logger.info("checkMembersInRoom - fetching member in the room ", { 
    roomId, 
    userId
  });

  const memberRef = query(collection(db, "members"), where('room_id', '==', roomId), where('user_id', '==', userId));
  const memberSnapshot = await getDocs(memberRef);
  
  if (memberSnapshot.size > 0) {
    Logger.info("checkMembersInRoom - member already exists ", {
      memberId: memberSnapshot.docs[0].id
    });

    return ({
      estimate: memberSnapshot.docs[0].data().estimate,
      memberId: memberSnapshot.docs[0].id,
    });
  }

  Logger.info("checkMembersInRoom - member doesn't exist ", {});

  return ({
    memberId: '',
    estimate: null,
  });
}; 

export const createRoomMember = async({
  roomId,
  userId,
}: { 
  roomId: string, 
  userId: string,
}) => {
  Logger.info("createRoomMember - verifying if current user exists as member in the room ", {
    roomId,
    userId
  });

  const { memberId, estimate } = await checkMembersInRoom({ roomId, userId });

  if (memberId !== '') {
    Logger.info("createRoomMember - current user already exists as member in the room ", {
      roomId,
      userId
    });

    return ({
      roomId,
      userId,
      memberId,
      roomOwnerId: userId,
      estimate,
    });
  }

  Logger.info("createRoomMember - current user doesn't exist as member in the room so create one now ", {
    roomId,
    userId
  });

  try {
    const membersDocumentRef = await addDoc(collection(db, "members"), {
      user_id: userId,
      room_id: roomId,
      estimate: null,
      created_at: Date.now(),
      updated_at: null,
    });

    const memberId = membersDocumentRef.id;

    Logger.info("new member created ", {
      memberId,
    });

    sessionStorage.setItem('roomId', roomId);
    sessionStorage.setItem('memberId', memberId);

    return ({
      roomId,
      userId,
      memberId,
      roomOwnerId: userId,
      estimate,
    });
  } catch (error: any) {
    console.error("failed to create members", error);
    return ({
      roomId: '',
      userId,
      memberId: '',
      roomOwnerId: '',
      estimate: null,
    });
  }
};

export const getMembersInRoom = async ({
  roomId,
}: {
  roomId: string;
}) => {
  try {
    Logger.info("getMembersInRoom - fetching room for roomID ", {
      roomId,
    });

    const roomDocument = await getDoc(doc(db, 'rooms', roomId));

    if (roomDocument.exists()) {
      Logger.info("getMembersInRoom - room found ", {
        roomId,
      });

      const owner = roomDocument.data().owner;

      Logger.info("getMembersInRoom - fetching members for roomID ", {
        roomId,
      }); 
      
      const membersRef = query(collection(db, "members"), where('room_id', '==', roomId));
      const querySnapshot = await getDocs(membersRef);
      let members: any = [];
      querySnapshot.forEach((member) => {
        members.push({
          roomId: member.data().room_id,
          userId: member.data().user_id,
          memberId: member.id,
          estimate: member.data().estimate,
          isOwner: member.data().user_id === owner,    
          roomOwnerId: member.data().user_id,
          isActive: roomDocument.data().is_active,
          showEstimates: roomDocument.data().show_estimates,
          error: '',
        });
      });

      return (members)
    }
    else {
      Logger.info("getMembersInRoom - no room found ", {});
      return ({
        status: 404,
        error: "Room not found",
      });
    }
  } catch (e) {
    Logger.error("getMembersInRoom - Error fetching document: ", {
      error: e
    });
  }
};

export const updateEstimates = async ({
  memberId,
  estimate
}: {
  memberId: string;
  estimate: string;
}) => {
  try {
    Logger.info("updating user estimate ", {
      memberId,
      estimate,
    });

    await setDoc(doc(db, "members", memberId), { estimate, updated_at: Date.now(), }, { merge: true });

    Logger.info("successfully updated estimate for member ", {
      memberId,
    });

    return ({
      status: 200,
    });
  } catch (e: any) {
    console.error("Error updating document: ", e);
    return ({
      status: 500,
      error: e?.message ?? "",
    });
  }
};

export const updateRoomShowEstimates = async ({
  roomId,
  showEstimates,
}: {
  roomId: string;
  showEstimates: boolean;
}) => {
  try {
    Logger.info("updating room show estimate ", {
      roomId,
      showEstimates,
    });

    await setDoc(doc(db, "rooms", roomId), { show_estimates: showEstimates, updated_at: Date.now(), }, { merge: true });

    Logger.info("successfully updated show estimates for room ", {
      roomId,
    });

    return ({
      status: 200,
    });
  } catch (e: any) {
    console.error("Error updating document: ", e);
    return ({
      status: 500,
      error: e?.message ?? "",
    });
  }
};

export const joinRoom = async ({
  roomId,
  userId,
}: {
  roomId: string;
  userId: string;
}) => {
  try {
    Logger.info("joinRoom - joining room ", {
      roomId,
      userId,
    });

    const roomDocument = await getDoc(doc(db, 'rooms', roomId));

    if (roomDocument.exists()) {
      Logger.info("joinRoom - room found ", { data: roomDocument.data() });
      const isActive = roomDocument.data().is_active;

      if (isActive) {
        Logger.info("joinRoom - room is active ", {});

        const {
          memberId,
          estimate,
        } = await createRoomMember({ roomId, userId });
    
        return ({
          roomId,
          userId,
          memberId,
          roomOwnerId: roomDocument.data().owner,
          estimate,
          isActive: roomDocument.data().is_active,
          showEstimates: roomDocument.data().show_estimates,
          error: '',
        })
      } else {
        Logger.info("joinRoom - room found but inactive ", {});

        return ({
          roomId,
          userId,
          memberId: '',
          roomOwnerId: userId,
          estimate: null,
          isActive: roomDocument.data().is_active,
          showEstimates: roomDocument.data().show_estimates,
          error: '',
        })
      }
    }
    else {
      Logger.info("joinRoom - no room found ", {});
      return ({
        status: 404,
        error: "Room not found",
      });
    }
  } catch (e) {
    Logger.error("joinRoom - Error fetching document: ", {
      error: e
    });
  }
};

export const getRoomDetails = async ({
  roomId,
}: {
  roomId: string;
}) => {
  try {
    Logger.info("getRoomDetails - fetch room details ", {
      roomId
    });

    const roomDocument = await getDoc(doc(db, 'rooms', roomId));

    if (roomDocument.exists()) {
      Logger.info("getRoomDetails - room found ", { roomData: roomDocument.data()});

      return ({
        roomId: roomId,
        roomOwnerId: roomDocument.data().owner,
        showEstimates: roomDocument.data().show_estimates,
        userId: '',
        memberId: '',
        estimate: null,
        isActive: roomDocument.data().is_active,
        error: '',
      });
    }
    else {
      Logger.info("getRoomDetails - no room found ", {});
      return ({
        status: 404,
        error: "Room not found",
      });
    }
  } catch (e) {
    Logger.error("Error fetching document: ", {
      error: e
    });
  }
};

export const deleteRoomEstimates = async ({
  roomId,
}: {
  roomId: string;
}) => {
  try {
    Logger.info("deleteEstimates - deleting estimate for room ", {
      roomId,
    });

    updateRoomShowEstimates({ roomId, showEstimates: false });
    const membersRef = query(collection(db, "members"), where('room_id', '==', roomId));
    const querySnapshot = await getDocs(membersRef);

    querySnapshot.forEach(async member => {
      try {
        await setDoc(doc(db, "members", member.id), {
          estimate: '',
          updated_at: Date.now(),
        }, { merge: true });
      } catch (error) {
        Logger.info("deleteEstimates - Error deleting estimates");
        return ({
          status: 500,
        });
      }
    });

    Logger.info("deleteEstimates - successfully deleted estimates for all members in room ", {
      roomId,
    });

    return ({
      msg: "success",
    });
  } catch (e: any) {
    console.error("Error updating document: ", e);
    return ({
      status: 500,
      error: e?.message ?? "",
    });
  }
};

export const getRoomDetailsByOwner = async ({
  ownerId,
}: {
  ownerId: string;
}) => {
  try {
    Logger.info("getRoomDetails - fetch room details by owner id ", {
      ownerId
    });

    const roomsRef = query(collection(db, "rooms"), where('owner', '==', ownerId));
    const roomDocument = await getDocs(roomsRef);

    if (roomDocument.size > 0) {
      Logger.info("getRoomDetails - room found ", {});

      return ({
        roomId: roomDocument.docs[0].id,
        roomOwnerId: ownerId,
        showEstimates: roomDocument.docs[0].data().show_estimates,
      });
    }
    else {
      Logger.info("getRoomDetails - no room found ", {});
      return ({
        status: 404,
        error: "Room not found",
      });
    }
  } catch (e) {
    Logger.error("Error fetching document: ", {
      error: e
    });
  }
};