import Tabs from '../../components/Tabs';

const profileTabs = [
  {
    name: 'accountInfo',
    label: 'Account Information',
    render: () => <p>Account information</p>,
  },
  {
    name: 'teams',
    label: 'Teams',
    render: () => (
      <p>teams</p>
    ),
  },
];

const Profile = () => (
  <Tabs isVertical tabs={profileTabs} />
);

export default Profile;
