import { createContext } from 'react';

import { User } from './types/user';
import { OwnerTeams, Team } from './types/team';

const AppContext = createContext({
  userId: '',
  setUser: (user: User) => {},
  user: {
    displayName: '',
    uid: '',
    emailVerified: false,
    email: '',
    phoneNumber: '',
    photoURL: ''
  },
  setCurrentTeam: (team: Team) => {},
  currentTeam: { teamId: '', name: '', teamOwnerId: '', createdAt: 0, teamMemberId: '' },
  setTeamsOwned: (team: OwnerTeams) => {},
  teamsOwned: [{
    teamId: '',
    name: '',
    isOwner: false,
    isActive: false,
    members: [],
  }],
});

export default AppContext;
