const PREFIX = "[LOGGER]: ";

export const info = (logName: string, logInfo?: Object) => {
  if (process.env.NODE_ENV === "development") {
    if (logInfo) {
      console.log(`${PREFIX}🔵 ${logName}`, logInfo && { ...logInfo });
    } else {
      console.log(`${PREFIX}🔵 ${logName}`);
    }
  }
};

export const success = (logName: string, logInfo?: Object) => {
  if (process.env.NODE_ENV === "development") {
    if (logInfo) {
      console.log(`${PREFIX}🟢 ${logName}`, logInfo && { ...logInfo });
    } else {
      console.log(`${PREFIX}🟢 ${logName}`);
    }
  }
};

export const warn = (logName: string, logInfo?: Object) => {
  if (process.env.NODE_ENV === "development") {
    if (logInfo) {
      console.log(`${PREFIX}🟡${logName}`, logInfo && { ...logInfo });
    } else {
      console.log(`${PREFIX}🟡${logName}`);
    }
  }
};

export const error = (logName: string, logInfo?: Object) => {
  if (process.env.NODE_ENV === "development") {
    if (logInfo) {
      console.log(`${PREFIX}🔴 ${logName}`, logInfo && { ...logInfo });
    } else {
      console.log(`${PREFIX}🔴 ${logName}`);
    }
  }
};

const Logger = { info, warn, error, success };

export default Logger;
