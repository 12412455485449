import Logger from "./logger";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

let firebaseApp;
let auth: any;
let analytics;
let db: any;

Logger.info("initialziing firebase", {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

try {
  firebaseApp = initializeApp(FIREBASE_CONFIG);
  auth = getAuth(firebaseApp);
  analytics = getAnalytics(firebaseApp);
  db = getFirestore(firebaseApp)
  Logger.success("successfully initialized firebase");
} catch (err) {
  Logger.error(`failed to initialize firebase ${err}`);
}

export { firebaseApp, db, analytics, auth };
