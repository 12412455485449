import { useCallback, useContext, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";

import AppContext from '../../AppContext';

import { emailRegister } from "./api";

interface RegisterProps {
  setViewType: (view: string) => void;
}

const Register =({ setViewType }: RegisterProps) => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();

  const teamId = new URLSearchParams(search).get('team') || '';
  
  sessionStorage.setItem('teamId', teamId);

  const { setUser } = useContext(AppContext);

  const handleEmailChange = useCallback((e: any) => {
    setUserEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: any) => {
    setUserPassword(e.target.value);
  }, []);

  const handleFormSubmit = useCallback((e: any) => {
    e.preventDefault();
    
    setIsLoading(true);

    emailRegister({ userEmail, userPassword})
    .then((data) => {
      setIsLoading(false);
      setUser(data);
      setViewType('addUserDetails');
    })
    .catch(err => {
      console.error(err);
      setIsLoading(false);
    });
  }, [setUser, setViewType, userEmail, userPassword]);

  const handleLoginClick = useCallback((e: any) => {
    e.preventDefault();
    setViewType('login');
  }, [setViewType]);

  return (
    <motion.div
      initial={{
        x: "70%",
        opacity: 0,
      }}
      className="auth-wrapper"
      animate={{
        x: "0",
        opacity: 1,
        height: "100%",
      }}
      transition={{ duration: 1, delay: 0.1 }}
    >
      <h4>Step into the enchanting world of scrum wizardry</h4>
      <form onSubmit={handleFormSubmit}>
        <input type="email" id="email" placeholder='Email' value={userEmail} onChange={handleEmailChange} />
        <input type="password" id="password" placeholder='Password' value={userPassword} onChange={handlePasswordChange} />
        <button type="submit" className={userEmail === '' || userPassword === '' || isLoading ? 'disabled' : ''}>Register</button>
      </form>
      <div>
        <p>Already on your interstellar journey?</p>
        <button className="link" onClick={handleLoginClick}>
          Login to explore more
          <FontAwesomeIcon icon={faArrowRight} style={{ color: "#fff", marginLeft: "5px", fontSize: "12px" }} />
        </button>
      </div>
    </motion.div>
  )
};

export default Register;
