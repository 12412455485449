import './PrivacyPolicy.scss';

const PrivacyPolicy = () => (
  <div className="policies">
    <h1 className="plus-jakarta-sans-800">
      Privacy Policy
    </h1>
    <h3 className="plus-jakarta-sans-500">
      Welcome to Scrum Wizardry. We value your privacy and are committed to protecting your personal data in accordance with the General Data Protection Regulation (GDPR). This privacy policy explains how we collect, use, and protect your information when you visit our website <a href="https://scrumwizardry.com">https://scrumwizardry.com</a> (the "Site").
    </h3>
    
    <ol className="policies-list">
      <li>
        <p className="plus-jakarta-sans-600">Information We Collect</p>
        <ol>
          <li>
            <p>Personal Information</p>
            <p>We may collect personal information that you provide to us, such as your name, email address, and other contact details when you register on our Site, subscribe to our newsletter, or contact us for support.</p>
          </li>
          <li>
            <p>Usage Data</p>
            <p>We may also collect information about your activity on the Site, such as the pages you visit, the links you click, and how long you stay on a page. This information helps us understand how you use the Site and improve your experience.</p>
          </li>
          <li>
            <p>Cookies and Tracking Technologies</p>
            <p>We use cookies and similar tracking technologies to enhance your experience on our Site. Cookies are small data files stored on your device. You can manage your cookie preferences through your browser settings.</p>
          </li>
        </ol>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">How We Use Your Information</p>
        <ol>
          <li>
            <p>To Provide and Maintain Our Services</p>
            <p>We use your information to operate and maintain our Site, including providing customer support and managing your account.</p>
          </li>
          <li>
            <p>To Improve Our Services</p>
            <p>We analyze usage data to understand how our Site is used and to improve its functionality and user experience.</p>
          </li>
          <li>
            <p>To Communicate with You</p>
            <p>We may use your contact information to send you updates, newsletters, and promotional materials. You can opt-out of these communications at any time.</p>
          </li>
          <li>
            <p>Legal and Regulatory Compliance</p>
            <p>We may use your information to comply with applicable laws, regulations, and legal processes.</p>
          </li>
        </ol>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">Information Sharing and Disclosure</p>
        <ol>
          <li>
            <p>Service Providers</p>
            <p>We may share your information with third-party service providers who assist us in operating our Site and providing our services. These providers are contractually obligated to protect your information.</p>
          </li>
          <li>
            <p>Business Transfers</p>
            <p>In the event of a merger, acquisition, or sale of our assets, your information may be transferred to the new owner.</p>
          </li>
          <li>
            <p>Legal Requirements</p>
            <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
          </li>
        </ol>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">Data Security</p>
        <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.</p>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">Your Data Protection Rights</p>
        <p>
          Depending on your location, you may have the following rights regarding your personal data:
        </p>
        <ul>
          <li>The right to access your data</li>
          <li>The right to correct inaccurate data</li>
          <li>The right to delete your data</li>
          <li>The right to restrict processing of your data</li>
          <li>The right to data portability</li>
          <li>The right to object to data processing</li>
          <li>To exercise these rights, please contact us at <a href="mailto:scrumwizardry@gmail.com">scrumwizardry@gmail.com</a></li>
        </ul>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">Children's Privacy</p>
        <p>Our Site is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">Changes to This Privacy Policy</p>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>
      </li>
      <li>
        <p className="plus-jakarta-sans-600">Contact Us</p>
        <p>
          If you have any questions or concerns about this privacy policy, please contact us at: <a href="mailto:scrumwizardry@gmail.com">scrumwizardry@gmail.com</a>
        </p>
      </li>
    </ol>
    <p>Thank you for trusting Scrum Wizardry with your personal data. We are committed to safeguarding your privacy.</p>
  </div>
);

export default PrivacyPolicy;
