import { updateProfile } from "firebase/auth";
import { doc, setDoc} from "firebase/firestore";

import { auth, db } from "../../services/firebase";
import Logger from "../../services/logger";
import { User } from "../../types/user";

import { addTeamMember } from '../AddTeam/api';

export const updateUser = async ({
  userName,
  userPhone,
  userPic,
  teamId,
}: {
  userName: string;
  userPhone?: string;
  userPic?: string; 
  teamId?: string;
}) => {
  Logger.info("updateUser - updating display name for current user ", {});

  try {
    updateProfile(auth.currentUser, {
      displayName: userName,
    });

    await setDoc(doc(db, "users", auth.currentUser.uid), { name: userName, updated_at: Date.now(), }, { merge: true });

    if (teamId) {
      addTeamMember({
        teamId,
        userId: auth.currentUser.uid,
      })
    }

    Logger.info("updateUser - successfully updated display name for user ", {
      userId: auth.currentUser.uid,
    });

    const userObj: User = {
      displayName: userName || '',
      uid: auth.currentUser.uid,
      emailVerified: auth.currentUser.emailVerified,
      email: auth.currentUser.email || '',
      phoneNumber: auth.currentUser.phoneNumber || '',
      photoURL: auth.currentUser.photoURL || '',
    };

    return (userObj);
  } catch (error: any) {
    const userObj: User = {
      displayName: '',
      uid: '',
      emailVerified: false,
      email: '',
      phoneNumber: '',
      photoURL: '',
    };
    return (userObj);
  }
};
