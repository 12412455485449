import React, { useContext, useEffect } from 'react';
import { collection, where, query, onSnapshot } from "firebase/firestore";

import { db } from "../../services/firebase";
import AppContext from '../../AppContext';

import Cards from './Cards';
import Members from './Members';

type PlanningPokerProps = {
  setMembersInRoom: (data: any[]) => void;
  membersInRoom: any[];
  roomDetails: {
    roomId: string;
    isRoomActive: boolean;
    roomOwnerId: string;
    memberId: string;
    showEstimates: boolean;
  }; 
};

const PlanningPoker: React.FC<PlanningPokerProps> = ({ setMembersInRoom, roomDetails, membersInRoom }) => {
  const { userId } = useContext(AppContext);
  
  useEffect(() => {
    const q = query(collection(db, "members"), where('room_id', '==', roomDetails.roomId));
    const unsubscribe = onSnapshot(q, (snapshot: { docs: any[]; }) => {
      const updatedData = snapshot.docs.map(doc => ({
        roomId: doc.data().room_id,
        userId: doc.data().user_id,
        memberId: doc.id,
        estimate: doc.data().estimate,        
        isOwner: doc.data().user_id === roomDetails.roomOwnerId,    
        roomOwnerId: doc.data().user_id,
        isActive: doc.data().is_active,
        showEstimates: doc.data().show_estimates,
        error: '',

      }));
      setMembersInRoom(updatedData);
    });

    // Cleanup function to unsubscribe when component unmounts
    return () => unsubscribe();
  }, [roomDetails.roomId, setMembersInRoom, roomDetails.roomOwnerId]);

  const selectedCard = membersInRoom.find((member: any) => member.userId === userId)?.estimate || '';

  return (
    <div className="content">
      <Cards userSelectedCard={selectedCard} memberId={roomDetails.memberId} roomId={roomDetails.roomId} />
      <Members
        membersInRoom={membersInRoom}
        isOwner={roomDetails.roomOwnerId === userId}
        roomId={roomDetails.roomId}
        showEstimates={roomDetails.showEstimates}
      />
    </div>
  )
};

export default PlanningPoker;
