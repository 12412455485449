import { useContext, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import AppContext from "./AppContext";

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(AppContext);

  return (
    <>
      {user.uid ? (
        <>
          {children}
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  )
};
