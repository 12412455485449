import './Wrapper.scss';

import { useCallback, useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard, faStar } from '@fortawesome/free-solid-svg-icons';

import { updateRoomShowEstimates, deleteRoomEstimates } from './api';

const Members = ({
  membersInRoom,
  isOwner,
  roomId,
  showEstimates,
}: {
  membersInRoom: any[],
  isOwner: boolean,
  roomId: string,
  showEstimates: boolean,
}) => {
  const [showWand, setShowWand] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showWand) {
      setTimeout(() => {
        setShowWand(false);
      }, 2500);
    }
  }, [showWand]);

  const toggleEstimate = useCallback(() => {
    if (!showEstimates) {
      setShowWand(true);
    }

    updateRoomShowEstimates({
      roomId,
      showEstimates: !showEstimates,
    })
  }, [roomId, showEstimates]);

  const deleteEstimate = useCallback(() => {
    setIsLoading(true);
    deleteRoomEstimates({ roomId }).finally(() => setIsLoading(false));
  }, [roomId]);

  return (
    <div className="members-list">
      {isOwner && (
        <>
          <div className="estimate-toggle">
            <motion.button
              whileHover={{
                scale: 1.1,
                backgroundColor: "#A71D31"
              }}
              transition={{ duration: 0.5, yoyo: Infinity }}
              onClick={deleteEstimate}
              className={`Button submit-btn danger ${isLoading && 'disabled'}`}
            >
              Delete estimates
            </motion.button>
            
            <motion.button
              whileHover={{
                scale: 1.1,
                backgroundColor: "#0171C2"
              }}
              transition={{ duration: 0.5, yoyo: Infinity }}
              onClick={toggleEstimate}
              className={`Button submit-btn`}
            >
              {!showEstimates ? 'Show estimates' : 'Hide estimates' }
            </motion.button>
          </div>
          {showWand && (
            <>
              <motion.div
                initial={{ scale: "0.6", left: "50%", opacity: 0, height: "60px", position: "absolute", top: "30%" }}
                animate={{ 
                  top: "25%",
                  opacity: 0.7,
                  rotate: "360deg",
                  scale: "1.2"
                }}
                transition={{
                  duration: 1,
                  delay: 0.04,
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
              </motion.div>
              <motion.div
                initial={{ scale: "0.6", left: "50%", opacity: 0, height: "60px", position: "absolute", top: "25%" }}
                animate={{ 
                  top: "25%",
                  left: "45%",
                  opacity: 0.7,
                  rotate: "270deg",
                  scale: "1.6"
                }}
                transition={{
                  duration: 1,
                  delay: 0.04,
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1.2em" }} />
              </motion.div>
              <motion.div
                initial={{ scale: "0.6", left: "50%", opacity: 0, height: "60px", position: "absolute", top: "5%" }}
                animate={{ 
                  top: "45%",
                  opacity: 0.7,
                  rotate: "270deg",
                  scale: "1.6"
                }}
                transition={{
                  duration: 1,
                  delay: 0.04,
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1.4em" }} />
              </motion.div>
              <motion.div
                initial={{ scale: "0.6", left: "50%", opacity: 0, height: "60px", position: "absolute", top: "5%" }}
                animate={{ 
                  top: "35%",
                  left: "55%",
                  opacity: 0.7,
                  rotate: "270deg",
                  scale: "1.6"
                }}
                transition={{
                  duration: 1,
                  delay: 0.04,
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ color: "#ffdf00", fontSize: "1em" }} />
              </motion.div>
            </>
          )}
        </>
      )}
      {roomId && (
        <div className="members">
          {membersInRoom && membersInRoom.length > 0 && (
            <>
              <div className="title">Active wizards in your battlefield</div>
              {membersInRoom.map((member: any, idx: number) => (
                <div key={member.memberId} className="wizard-row">
                  <FontAwesomeIcon icon={faHatWizard} style={{ color: "#00B2B0", marginRight: "10px" }} />
                  <div className="name">{`Wizard ${idx + 1}`}</div>
                    &nbsp;
                    <div className="estimate">
                      {showEstimates && !showWand ? member.estimate : (
                        <div className="card-bg">{member.estimate ? '' : '-'}</div>
                      )}
                    </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
};

export default Members;
