import './Tabs.scss';

import { useState, ReactElement } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';

type TabsProps = {
  tabs: {
    name: string;
    label: string;
    render: () => ReactElement;
  }[];
  isVertical: boolean;
};

const tabContentVerticalVariants: Variants = {
  initial: {
    x: 10,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1
  },
  exit: {
    x: -10,
    opacity: 0
  }
};

const tabContentHorizontalVariants: Variants = {
  initial: {
    y: 10,
    opacity: 0
  },
  enter: {
    y: 0,
    opacity: 1
  },
  exit: {
    y: -10,
    opacity: 0
  }
};

const Tabs = ({ tabs, isVertical }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<any>(tabs[0]);

	const handleClick = (e: any, tab: any) => {
		e.preventDefault();

		setActiveTab(tab);
	}

	const isSelected = (tab: any) => activeTab.name === tab.name;

	return (
		<div className={`tabWrapper ${isVertical && 'vertical'}`}>

			<div className="tabHeader">
				{tabs.map((tab) => (
					<div
						key={tab.name}
						className={["tabItem", isSelected(tab) ? 'selected' : ''].join(' ')}
					>
						<a href="#" onClick={(e: any) => handleClick(e, tab)} >
							{tab.label}
						</a>

						{isSelected(tab) && !isVertical && <motion.div layoutId='indicator' className="indicator" />}
					</div>
				))}
			</div>
      <div className="tabContent">
				<AnimatePresence mode='wait'>
					<motion.div
						key={activeTab.name || "empty"}
						variants={isVertical ? tabContentVerticalVariants : tabContentHorizontalVariants}
						initial="initial"
						animate="enter"
						exit="exit"
						transition={{
							duration: .3
						}}
					>
						{activeTab && activeTab?.render()}
					</motion.div>
				</AnimatePresence>
			</div>
			

		</div>
	)
};

export default Tabs;
